/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
.nofocus, #search_block_top .btn.button-search:focus, #search_block_top #search_query_top:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  outline: none; }

.bs-transition, .toggle-search, #search_block_top {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.toggle-search {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }
  .toggle-search i {
    cursor: pointer;
    font-size: 15px;
    margin: 0 1px; }
  .search-revealed .toggle-search {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }

#search_block_top {
  position: absolute;
  right: 30px;
  top: 0;
  left: 200px;
  height: 100%;
  z-index: 2;
  background: #f8f8f8;
  -webkit-transform: translateX(50%) scaleX(0);
  -moz-transform: translateX(50%) scaleX(0);
  -ms-transform: translateX(50%) scaleX(0);
  -o-transform: translateX(50%) scaleX(0);
  transform: translateX(50%) scaleX(0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0; }
  #search_block_top .crux-cross {
    cursor: pointer; }
  .search-revealed #search_block_top {
    -webkit-transform: translateX(0) scaleX(1);
    -moz-transform: translateX(0) scaleX(1);
    -ms-transform: translateX(0) scaleX(1);
    -o-transform: translateX(0) scaleX(1);
    transform: translateX(0) scaleX(1);
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    right: 0; }
  #search_block_top .btn.button-search {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: transparent;
    width: 50px;
    text-align: left;
    padding: 0;
    line-height: 30px; }
    #search_block_top .btn.button-search span {
      display: none; }
    #search_block_top .btn.button-search:after {
      content: '';
      display: block;
      height: 22px;
      position: absolute;
      border-right: 1px solid #464646;
      right: 0;
      top: 50%;
      margin-top: -11px; }
    #search_block_top .btn.button-search:before {
      content: "\f002";
      display: block;
      font-family: "FontAwesome";
      width: 100%;
      line-height: 30px;
      text-align: center; }
  #search_block_top #search_query_top {
    display: inline;
    padding: 13px 13px 13px 60px;
    line-height: 30px;
    border: none;
    height: 30px;
    margin-right: 1px;
    color: #333333;
    font-family: "Kaleko 105 Round W01 Book", Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 300;
    background: transparent; }
    #search_block_top #search_query_top:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }

.ac_results {
  background: #fff;
  border: 1px solid #d6d4d4;
  width: 271px;
  margin-top: -1px; }
  .ac_results li {
    padding: 0 10px;
    font-weight: normal;
    color: #686666;
    font-size: 13px;
    line-height: 22px; }
    .ac_results li.ac_odd {
      background: #fff; }
    .ac_results li:hover, .ac_results li.ac_over {
      background: #fbfbfb; }

form#searchbox {
  position: relative; }
  form#searchbox label {
    color: #333; }
  form#searchbox input#search_query_block {
    margin-right: 10px;
    max-width: 222px;
    margin-bottom: 10px;
    display: inline-block;
    float: left; }
  form#searchbox .button.button-small {
    float: left; }
    form#searchbox .button.button-small i {
      margin-right: 0; }
